import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home.js';
import TermsConditions from './components/Pages/Terms.js';
import Checkout from './components/Pages/Checkout.js';
// import PaymentSuccessPage from './components/Pages/PaymentPage.js';
// import EventWorkshop from "./components/Pages/EventWorkshop.js";
// import PaymentPage from "./components/Pages/Payment/PaymentPage.js";
// import PaymentSuccess from "./components/Pages/Payment/PaymentSuccess.js";
// import OrderDetailsPage from "./components/Pages/Payment/OrderDetailsPage.js";
// import ConfirmPaymentPage from "./components/Pages/Payment/ConfirmPaymentPage.js";
// import OffcanvasExample from './components/Main/NavBar.js';

import './App.css'
import ChatPage from "./components/Pages/ChatPage.js";
import ContactPage from "./components/Pages/Contact/ContactPage.js";
import ChatbotWebView from "./components/Pages/ChatbotWebView.js";
// import FirstModal from "./components/Main/FirstModal.js";
export default function App() {
  // Initialize state with a default value / from localStorage
  const [toggleDarkMode, setToggleDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true';
  });

  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   const hasSeenModal = localStorage.getItem('hasSeenModal');
  //   if (!hasSeenModal) {
  //     setShowModal(true);
  //     localStorage.setItem('hasSeenModal', 'true');
  //   }
  // }, []);

    useEffect(() => {
        // Disable right-click
        const disableRightClick = (e) => {
            e.preventDefault();
        };

        // Disable keyboard shortcuts
        const disableShortcuts = (e) => {
            if (
                (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl+Shift+I
                (e.ctrlKey && e.key === 'u') || // Ctrl+U
                (e.key === 'F12') // F12
            ) {
                e.preventDefault();
            }
        };

        // Detect dev tools opening
        const detectDevTools = () => {
            const threshold = 100; // Width/height difference threshold
            if (
                window.outerWidth - window.innerWidth > threshold ||
                window.outerHeight - window.innerHeight > threshold
            ) {
            }
        };

        // Add event listeners
        document.addEventListener('contextmenu', disableRightClick);
        document.addEventListener('keydown', disableShortcuts);
        const devToolsCheckInterval = setInterval(detectDevTools, 1000);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
            document.removeEventListener('keydown', disableShortcuts);
            clearInterval(devToolsCheckInterval);
        };
    }, []);

  useEffect(() => {
    // Save the theme mode to localStorage whenever it changes
    localStorage.setItem('darkMode', toggleDarkMode);
  }, [toggleDarkMode]);

  const toggleDarkTheme = () => {
    setToggleDarkMode(prevMode => !prevMode);
  };

  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? 'dark' : 'light',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#131052',
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
      {/* {showModal && <FirstModal onClose={() => setShowModal(false)} />} */}
        <Routes>
          <Route path="/" element={<Home toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/terms" element={<TermsConditions  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/contact" element={<ContactPage  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/ai-agent" element={<ChatPage  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          <Route path="/chat-ai-agent" element={<ChatbotWebView />} />
          {/* <Route path="/event/workshop" element={<EventWorkshop  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} /> */}
          <Route 
          path="/checkout"
          element={<Checkout  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} />
          {/* <Route 
          path="/payment_tlync_ls_wallet"
          // path="/payment" 
          element={<PaymentSuccessPage  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} /> */}

          {/* the payment page */}

          {/* <Route path="/payment/webhook" element={<PaymentPage  toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />} /> */}
          {/* <Route path="/confirm-payment" element={<ConfirmPaymentPage />} /> */}
          {/* <Route path="/order-details" element={<OrderDetailsPage />} /> */}
          {/* <Route path="/payment-success" element={<PaymentSuccess />} /> */}
        {/* <Route path="/payment-failure" element={<PaymentFailure />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
