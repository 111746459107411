import React from 'react';
import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';

const clientId = "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319";

const ChatbotWebView = () => {
      const client = getClient({ clientId });
  
      const configuration = {
          color: '#044FB0',
          botName: "LibyaShop",
          clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
          hostUrl: 'https://webchat.botpress.cloud',
          avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
          phoneNumber: "091-898-7171",
          termsConditions: "https://libyashop.ly/terms",
          privacyPolicy: "https://libyashop.ly/",
          emailAddress: "@support.com",
          website: "https://libyashop.ly/",
          useSessionStorage: true,
          showBotInfoPage: true,
          enableConversationDeletion: true,
          showPoweredBy: false,
          hideWidget: true,
          disableAnimations: true,
      };

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <WebchatProvider client={client} configuration={configuration}>
                <Webchat />
            </WebchatProvider>
        </div>
    );
};

export default ChatbotWebView;